<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn :to="{name: 'PartnerIndex'}" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>История партнерской программы</v-toolbar-title>
      <template v-slot:extension>
        <v-tabs v-model="balanceTab" centered grow>
          <v-tab to="/partner">Вывести</v-tab>
          <v-tab to="/partner/history">История</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-container v-if="!loadPage">
      <!-- <v-row class="align-center">

        <v-col cols="8" sm="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRangeText"
                label="Выберите дату"
                prepend-icon="$vuetify.icons.calendar"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="ru-ru"
              :first-day-of-week="1"
              v-model="dates"
              range
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Закрыть</v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(dates)">Принять</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4" sm="4">
          <v-btn dark color="primary" @click="sortTransactions">Искать</v-btn>
        </v-col>
      </v-row> -->

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дата</th>
              <!-- <th class="text-left">Тип</th> -->
              <th class="text-left">Сумма</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in transactions" :key="item.id">
              <td>{{ item.date }}</td>
              <!-- <td>{{ item.type_formated }}</td> -->
              <td>{{ item.summ }} р.</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row v-if="!transactions || transactions.length == 0" align="center">
        <no-content route-name title="Данных нет"></no-content>
      </v-row>

      <v-row align="center">
        <v-col cols="12">
          <v-pagination
            v-if="pages > 1"
            @input="goPage"
            v-model="page"
            :length="pages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    page: 1,
    pages: 0,
    dates: [],
    menu: false
  }),
  methods: {
    getTransactions() {
      this.loading = true;
      let app = this;
      this.$store
        .dispatch("cabinet_partner/getTransactions", {
          page: app.page,
          dates: app.dates,
        }).then(function(response) {
          app.pages = response.data.transactions.last_page;
         
        });
    },
    goPage(nextPage) {
      // console.log('nextPage',nextPage);
      //   console.log(this.$route.query.page);
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          //   params: { id: this.bookId },
          query: { page: nextPage, dates: this.dates }
        });
      }
    },
    sortTransactions() {
      this.$router.push({
        name: "PartnerHistory",
        //   params: { id: this.bookId },
        query: { page: 1, dates: this.dates }
      });
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      transactions: "cabinet_partner/transactions"
    }),
    dateRangeText() {
      if (this.dates.length > 1) {
        return this.dates.join(" ~ ");
      }
      return this.dates;
    }
  },
  mounted() {

    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    let date_raw = this.$route.query.dates ? this.$route.query.dates : [];

    if(typeof(date_raw) === 'string'){
        this.dates = [date_raw]
    }
    else{
        this.dates = date_raw;
    }
    this.getTransactions();
  }
};
</script>
